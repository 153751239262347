<template>
  <v-container grid-list-md>
    <v-toolbar class="mb-1">
      <v-toolbar-title>Student Dashboard</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3">
        <banner-links></banner-links>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <student-links></student-links>
      </v-col>
      <v-col cols="12" lg="6">
        <music-widget></music-widget>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    BannerLinks: () => import('@/components/dashboard/Banner'),
    StudentLinks: () => import('@/components/dashboard/Student'),
    MusicWidget: () => import('@/components/student/music/widget')
  }
}
</script>
